import React, { useState } from "react";
import { useRef } from "react";
// import { Link } from "gatsby";
import styled from "@emotion/styled";
import { Global, css } from "@emotion/core";

// import Prism from "prismjs";

import Layout from "../components/layout";
import SEO from "../components/seo";
// import Pulse from "../components/pulse";

const PreventScrolling = () => (
  <Global
    styles={css`
      html {
        overflow: hidden;
      }
    `}
  />
);

const Stacked = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 100vh;
  }
`;

const Sandbox = () => {
  const manRef = useRef();

  const opts = { block: "center", behavior: "smooth" };

  const [ps, setPs] = useState(true);

  return (
    <Layout>
      <SEO title="Sandbox" />
      {ps && <PreventScrolling />}
      <Stacked>
        <div>
          hello
          <pre>{JSON.stringify(opts)}</pre>
          <button
            onClick={() => {
              setPs(false);
              manRef.current.scrollIntoView(opts);
            }}
          >
            scroll to man
          </button>
        </div>
        <div>world</div>
        <div ref={manRef}>man</div>
        <div>ack</div>
      </Stacked>
    </Layout>
  );
};

export default Sandbox;
